import(/* webpackMode: "eager" */ "/codebuild/output/src3079121603/src/bmw-head-frontend/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3079121603/src/bmw-head-frontend/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3079121603/src/bmw-head-frontend/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3079121603/src/bmw-head-frontend/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3079121603/src/bmw-head-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Manrope\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"manrope\"}");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/codebuild/output/src3079121603/src/bmw-head-frontend/node_modules/nextjs-toploader/dist/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/codebuild/output/src3079121603/src/bmw-head-frontend/src/app/providers.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src3079121603/src/bmw-head-frontend/src/styles/globals.css");
